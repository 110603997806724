@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
    .overlay {
      border-image: linear-gradient(hsl(240 100% 20% / 0.6), hsl(0 100% 20% / 0.6))
        fill 1;
    }
  }
}

:root {
  --blue-rgb: 92 192 249;
  --green-rgb: 125 161 35;
  --brown-rgb: 127 46 23;
}

*, *::before, *::after { box-sizing: border-box}
*   {
  margin:0;
  padding:0;
  }
html{
  padding: 0;
  margin: 0;
}
a {
  text-decoration: none !important;
}

body {
  position: absolute;
  /* background-color: rgba(238,233,221, 0.5);  */
  background-color: #ffffff; 
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.logo {
  /* animation: rotating 10s cubic-bezier(0.2, 0.1, 0.2, 1) infinite alternate; */
}


.shadow-card {
  /* background-size: cover; */
  /* background-position: center; */
  position: relative;
  cursor: pointer;
  outline: none;
  transition: scale 100ms;
}

.shadow-card .card-front-image {
  position: relative;
  z-index: 2;
}

.shadow-card .card-image {
  /* aspect-ratio: 2 / 3; */
}

.card-faders {
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 1;
  opacity: 0;
  transition: opacity 1500ms;
  pointer-events: none;
}

.shadow-card .card-faders {
  opacity: 1;
}

.card:active {
  scale: 0.98;
}

.card-fader {
  position: absolute;
  left: 0px;
  top: 0px;
}

.card-fader:nth-child(odd) {
  animation: fade-left 3s linear infinite;
}

.card-fader:nth-child(even) {
  animation: fade-right 3s linear infinite;
}

.card-fader:is(:nth-child(3), :nth-child(4)) {
  animation-delay: 750ms;
}

.card-fader:is(:nth-child(5), :nth-child(6)) {
  animation-delay: 1500ms;
}

.card-fader:is(:nth-child(7), :nth-child(8)) {
  animation-delay: 2250ms;
}



@keyframes fade-left {
  from {
    scale: 1;
    translate: 0%;
    opacity: 1;
  }
  
  to {
    scale: 0.8;
    translate: -30%;
    opacity: 0;
  }
}

@keyframes fade-right {
  from {
    scale: 1;
    translate: 0%;
    opacity: 1;
  }
  
  to {
    scale: 0.8;
    translate: 30%;
    opacity: 0;
  }
}